<template>
  <div class="app-container equityheader summary-page">
    <eHeader
      ref="header"
      :query="query"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="userName" label="客户昵称"/>
      <el-table-column prop="amount" label="充值数量(USDT)"/>
      <el-table-column label="充值方式">
        <template slot-scope="scope">
          <span>{{getChargeType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channelProtocolNo" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channelProtocolName" label="时间">
        <template slot-scope="scope">
          <span>{{getTime(scope.row)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import { parseTime } from '../../../utils'
  import initData from '@/mixins/initData'
  import eHeader from '@/components/financial/rechargeRecord/header'
  export default {
    components: { eHeader },
    mixins: [initData],
    data() {
      return {
        statistics: {
          "amount": '',// "总余额",
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      beforeInit() {
        this.url = '/community/crm/getPrepaidRecord'
        this.params = {
          page: this.page,
          size: this.size,
          status: this.query.status,
          id: this.query.id,
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(this.query.startTime){
          this.params.startTime = parseTime(this.query.startTime);
        }
        if(this.query.endTime){
          this.params.endTime = parseTime(this.query.endTime);
        }
        if(this.query.userName){
          this.params.userName = this.query.userName;
        }
        return true;
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 3:
              sums[index] = `${this.statistics.amount}`;
              break;
          }
        });
        return sums;
      },
      getStatus(row){
        switch (row.status) {
          case 1:
            return '待确认';
          case 3:
            return '已完成';
        }
      },
      getChargeType(row){
        switch (row.intoType) {
          case 1:
            return '钱包内转';
          case 2:
            return '在线支付';
        }
      },
      getTime(row){
        return parseTime(row.time);
      }
    }
  }
</script>

<style lang="less" scoped>
.equityheader {

}
</style>
